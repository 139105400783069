import React, {useMemo} from "react"
import { Table } from "semantic-ui-react"
import {formatDuration, formatNumber, formatTime} from 'utils/time-formatter'
import {getActiveRange, getYesterday} from 'utils/time'
import ApiDayData from 'interfaces/apis/sales/day'
import DayRow from 'components/sales/table/day-row'
import { SemanticLoader } from 'components/semantic-loader/semantic-loader'
import {CellValue} from "./cell-value";
import {useSelector} from "react-redux";
import {RootState} from "../../../interfaces/states/redux/root-state";
import {ApiCurrentUser} from "../../../interfaces/apis/users/current-user";
import Visibility from "../../utils/visibility";

interface Props {
  today: ApiDayData | undefined;
  yesterday: ApiDayData | undefined;
}

export const SalesTable = (props: Props) => {
  const {yesterday, today} = props;

  const isMY = () => window.location.origin.includes('.com.my');
  const isSG = () => window.location.origin.includes('.com.sg');

  const getCurrency = () => {
    if(isMY()) return 'RM'
    else if(isSG()) return 'SGD'
    else return 'RM'
  }

  const userInfo = useSelector<RootState, ApiCurrentUser>(
      state => state.auth.currentUserInfo)
  const userEmail = useMemo(() => {
    return userInfo?.userInfo?.email ?? ''
  }, [userInfo])

  return <SemanticLoader active={!today || !yesterday} inverted={true}>

    <Table unstackable structured celled striped compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>Description</Table.HeaderCell>
          <Table.HeaderCell width={1}>Today <br/>({formatTime(new Date().getTime(), 'DATE_ONLY')})</Table.HeaderCell>
          <Table.HeaderCell width={1}>Yesterday <br/>({formatTime(getYesterday(), 'DATE_ONLY')})</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {
          today && yesterday ? (

          <React.Fragment>

            <Table.Row positive={today && getActiveRange() === '2PM'}>
              <Table.Cell colSpan={3}><h2>2PM</h2></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.actualSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.actualSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>LW Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.lastWeekSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.lastWeekSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Target (<CellValue value={today.twoPM.targetPercentage*100}/>%) ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.targetSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.targetSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>vs Target (%)</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.againstTargetPercentage*100}/>%</Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.againstTargetPercentage*100}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.actualLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.actualLabor}/></Table.Cell>
            </Table.Row>
            <Visibility condition={['developer@whenso.com'].includes(userEmail)}>
              <Table.Row>
                <Table.Cell><b>Actual Rider (hours)</b></Table.Cell>
                <Table.Cell><b><CellValue value={today.twoPM.actualRider}/></b></Table.Cell>
                <Table.Cell><b><CellValue value={yesterday.twoPM.actualRider}/></b></Table.Cell>
              </Table.Row>
            </Visibility>
            <Table.Row>
              <Table.Cell>Target Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.twoPM.targetLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.twoPM.targetLabor}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sales per 1000 - Labor</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.twoPM.actualLabor / (today.twoPM.actualSales / 1000)) }/></Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.twoPM.actualLabor / (yesterday.twoPM.actualSales / 1000))}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Labor Cost in %</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.twoPM.actualLabor * 11.0 / today.twoPM.actualSales * 100)}/>%</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.twoPM.actualLabor * 11.0 / yesterday.twoPM.actualSales * 100) }/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>DT (mins)</Table.Cell>
              <Table.Cell><CellValue value={formatDuration(today.twoPM.averageDeliveryTime)}/></Table.Cell>
              <Table.Cell><CellValue value={formatDuration(yesterday.twoPM.averageDeliveryTime)}/></Table.Cell>
            </Table.Row>



            <Table.Row positive={today && getActiveRange() === '5PM'}>
              <Table.Cell colSpan={3}><h2>5PM</h2></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.actualSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.actualSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>LW Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.lastWeekSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.lastWeekSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Target (<CellValue value={today.fivePM.targetPercentage*100}/>%) ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.targetSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.targetSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>vs Target (%)</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.againstTargetPercentage*100}/>%</Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.againstTargetPercentage*100}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.actualLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.actualLabor}/></Table.Cell>
            </Table.Row>
            <Visibility condition={['developer@whenso.com'].includes(userEmail)}>
              <Table.Row>
                <Table.Cell><b>Actual Rider (hours)</b></Table.Cell>
                <Table.Cell><b><CellValue value={today.fivePM.actualRider}/></b></Table.Cell>
                <Table.Cell><b><CellValue value={yesterday.fivePM.actualRider}/></b></Table.Cell>
              </Table.Row>
            </Visibility>
            <Table.Row>
              <Table.Cell>Target Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.fivePM.targetLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.fivePM.targetLabor}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sales per 1000 - Labor</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.fivePM.actualLabor / (today.fivePM.actualSales / 1000))}/></Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.fivePM.actualLabor / (yesterday.fivePM.actualSales / 1000))}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Labor Cost in %</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.fivePM.actualLabor * 11.0 / today.fivePM.actualSales * 100 )}/>%</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.fivePM.actualLabor * 11.0 / yesterday.fivePM.actualSales * 100 )}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>DT (mins)</Table.Cell>
              <Table.Cell><CellValue value={formatDuration(today.fivePM.averageDeliveryTime)}/></Table.Cell>
              <Table.Cell><CellValue value={formatDuration(yesterday.fivePM.averageDeliveryTime)}/></Table.Cell>
            </Table.Row>



            <Table.Row positive={today && getActiveRange() === '9PM'}>
              <Table.Cell colSpan={3}><h2>9PM</h2></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.actualSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.actualSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>LW Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.lastWeekSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.lastWeekSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Target (<CellValue value={today.ninePM.targetPercentage*100}/>%) ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.targetSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.targetSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>vs Target (%)</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.againstTargetPercentage*100}/>%</Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.againstTargetPercentage*100}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.actualLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.actualLabor}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Target Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.ninePM.targetLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.ninePM.targetLabor}/></Table.Cell>
            </Table.Row>
            <Visibility condition={['developer@whenso.com'].includes(userEmail)}>
              <Table.Row>
                <Table.Cell><b>Actual Rider (hours)</b></Table.Cell>
                <Table.Cell><b><CellValue value={today.ninePM.actualRider}/></b></Table.Cell>
                <Table.Cell><b><CellValue value={yesterday.ninePM.actualRider}/></b></Table.Cell>
              </Table.Row>
            </Visibility>
            <Table.Row>
              <Table.Cell>Sales per 1000 - Labor</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.ninePM.actualLabor / (today.ninePM.actualSales / 1000))}/></Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.ninePM.actualLabor / (yesterday.ninePM.actualSales / 1000))}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Labor Cost in %</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.ninePM.actualLabor * 11.0 / today.ninePM.actualSales * 100)}/>%</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.ninePM.actualLabor * 11.0 / yesterday.ninePM.actualSales * 100)}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>DT (mins)</Table.Cell>
              <Table.Cell><CellValue value={formatDuration(today.ninePM.averageDeliveryTime)}/></Table.Cell>
              <Table.Cell><CellValue value={formatDuration(yesterday.ninePM.averageDeliveryTime)}/></Table.Cell>
            </Table.Row>





            <Table.Row positive={today && getActiveRange() === '11PM'}>
              <Table.Cell colSpan={3}><h2>11PM</h2></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.actualSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.actualSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>LW Sales ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.lastWeekSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.lastWeekSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Target (<CellValue value={today.elevenPM.targetPercentage*100}/>%) ({getCurrency()})</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.targetSales}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.targetSales}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>vs Target (%)</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.againstTargetPercentage*100}/>%</Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.againstTargetPercentage*100}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Actual Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.actualLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.actualLabor}/></Table.Cell>
            </Table.Row>
            <Visibility condition={['developer@whenso.com'].includes(userEmail)}>
              <Table.Row>
                <Table.Cell><b>Actual Rider (hours)</b></Table.Cell>
                <Table.Cell><b><CellValue value={today.elevenPM.actualRider}/></b></Table.Cell>
                <Table.Cell><b><CellValue value={yesterday.elevenPM.actualRider}/></b></Table.Cell>
              </Table.Row>
            </Visibility>
            <Table.Row>
              <Table.Cell>Target Labour (hours)</Table.Cell>
              <Table.Cell><CellValue value={today.elevenPM.targetLabor}/></Table.Cell>
              <Table.Cell><CellValue value={yesterday.elevenPM.targetLabor}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sales per 1000 - Labor</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.elevenPM.actualLabor / (today.elevenPM.actualSales / 1000)) }/></Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.elevenPM.actualLabor / (yesterday.elevenPM.actualSales / 1000))}/></Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Labor Cost in %</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(today.elevenPM.actualLabor * 11.0 / today.elevenPM.actualSales * 100)}/>%</Table.Cell>
              <Table.Cell><CellValue value={formatNumber(yesterday.elevenPM.actualLabor * 11.0 / yesterday.elevenPM.actualSales * 100)}/>%</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>DT (mins)</Table.Cell>
              <Table.Cell><CellValue value={formatDuration(today.elevenPM.averageDeliveryTime)}/></Table.Cell>
              <Table.Cell><CellValue value={formatDuration(yesterday.elevenPM.averageDeliveryTime)}/></Table.Cell>
            </Table.Row>


          </React.Fragment>





              // <React.Fragment>
              //   <DayRow today={true} title={<React.Fragment>Today <br/>({formatTime(new Date().getTime(), 'DATE_ONLY')})</React.Fragment>} day={today} />
              //   <DayRow today={false} title={<React.Fragment>Yesterday<br/>({formatTime(getYesterday(), 'DATE_ONLY')})</React.Fragment>} day={yesterday} />
              // </React.Fragment>
          ) :
          <Table.Row>
            <Table.Cell colSpan="100%" style={{ textAlign: 'center' }}>
              No Result
            </Table.Cell>
          </Table.Row>
        }
      </Table.Body>
    </Table>

  </SemanticLoader>
}
